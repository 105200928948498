import type { RouteRecordRaw } from 'vue-router'

const router: RouteRecordRaw = {
  path: 'bill-of-material',
  redirect: {
    name: 'product.detail.bill-of-material.group-by-part-by-material-set'
  },
  name: 'product.detail.bill-of-material',
  component: () =>
    import(
      '@/views/costing/product/detail/components/bill-of-material/BillOfMaterialPage.vue'
    ),
  children: [
    {
      path: 'all',
      name: 'product.detail.bill-of-material.all',
      component: () =>
        import(
          '@/views/costing/product/detail/components/bill-of-material/reports/all-material/ReportAllMaterial.vue'
        )
    },
    {
      path: 'group-by-part',
      name: 'product.detail.bill-of-material.group-by-part',
      component: () =>
        import(
          '@/views/costing/product/detail/components/bill-of-material/reports/group-by-part/ReportGroupByPart.vue'
        )
    },
    {
      path: 'group-by-box',
      name: 'product.detail.bill-of-material.group-by-box',
      component: () =>
        import(
          '@/views/costing/product/detail/components/bill-of-material/reports/group-by-box/ReportGroupByBox.vue'
        )
    },
    {
      path: 'group-by-material-set',
      name: 'product.detail.bill-of-material.group-by-material-set',
      component: () =>
        import(
          '@/views/costing/product/detail/components/bill-of-material/reports/group-by-material-set/ReportGroupByMaterialSet.vue'
        )
    },
    {
      path: 'group-by-part-by-material-set',
      name: 'product.detail.bill-of-material.group-by-part-by-material-set',
      component: () =>
        import(
          '@/views/costing/product/detail/components/bill-of-material/reports/group-by-part-by-material-set/ReportGroupByPartByMaterialSet.vue'
        )
    }
  ]
}

export default router
